import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import variables from '_config/css-variables'

const AppContext = React.createContext()

const DEFAULT_STATE = {
  navbarColor: variables['color-primary'],
  navbarLight: false,
  navbarCompact: false,
  customBrandColor: null,
}

class AppContextProvider extends PureComponent {
  constructor(props) {
    super(props)

    this.state = DEFAULT_STATE
  }

  setNavbarColor = color => {
    if (color) {
      this.setState({ navbarColor: color })
    }
  }

  setNavbarLight = value => {
    this.setState({ navbarLight: value })
  }

  setNavbarCompact = value => {
    this.setState({ navbarCompact: value })
  }

  setNavbarDefaults = () => {
    this.setState(DEFAULT_STATE)
  }

  setCustomBrandColor = value => {
    this.setState({ customBrandColor: value })
  }

  render() {
    const { children } = this.props

    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setNavbarColor: this.setNavbarColor,
          setNavbarLight: this.setNavbarLight,
          setNavbarCompact: this.setNavbarCompact,
          setNavbarDefaults: this.setNavbarDefaults,
          setCustomBrandColor: this.setCustomBrandColor,
        }}
      >
        {children}
      </AppContext.Provider>
    )
  }
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { AppContext, AppContextProvider }
