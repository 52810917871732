import React from 'react'
import config from 'react-reveal/globals'
import smoothscroll from 'smoothscroll-polyfill'

import { AppContextProvider } from './src/context'

export const onClientEntry = () => {
  config({ ssrFadeout: true })
  smoothscroll.polyfill()
}

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => <AppContextProvider>{element}</AppContextProvider>
